import "./styles/libs/sanitize.scss";
import "./styles/global-styles.scss";
import "./styles/global-utilities.scss";

export const onRouteUpdate = ({ location }) => {
  const lastPath = location.href.replace(location.origin, "");
  if (lastPath !== "/callback" && location.pathname.match(/^\/secure/)) {
    sessionStorage.setItem("lastPath", lastPath);
  }
};

export const onInitialClientRender = () => {
  if (!process.env.NETLIFY) {
    // dirty fix for missing popstate listener
    // @see https://stackoverflow.com/questions/68145056/gatsby-404-page-thrown-on-browser-navigation
    const GATSBY_NAVIGATE = window.___navigate || {};
    window.addEventListener("popstate", () =>
      GATSBY_NAVIGATE(window.location.pathname, { replace: true })
    );
  }
};
